<template>
  <div class="member-container">
    <ProcessIntroduction />
    <userJurisdiction />
    <section class="content">
      <ul class="tab-wrap">
        <div class="container_media">
          <li :class="active === item.eventName ? 'tab active' : 'tab'" v-for="item in menus" :key="item.eventName"
            @click="handleMenuClick(item)">
            <el-image v-show="active === item.eventName" :src="item.icon" />
            <el-image v-show="active !== item.eventName" :src="item.un_icon" />
            <span>{{ item.name }}</span>
          </li>
        </div>
      </ul>
      <div class="main">
        <router-view />
      </div>
    </section>
  </div>
</template>

<script>
import ProcessIntroduction from '@/views/newMember/components/ProcessIntroduction'
import userJurisdiction from '@/views/newMember/components/userJurisdiction'
import { memberMenus } from '@/config'
import Buttons from '@/layout/mixins/Buttons'
export default {
  components: {
    ProcessIntroduction,
    userJurisdiction,
  },
  mixins: [Buttons],
  watch: {
    '$route.path': {
      handler(val) {
        const info = this.menus.filter((i) => i.path === val)[0]
        const hrefInfo = this.menus.filter(
          (i) => i.path === window.location.href
        )[0]

        if (info) {
          this.active = info.eventName
        }
        if (hrefInfo) {
          this.active = hrefInfo.eventName
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      menus: memberMenus,
      active: 'MyMember'
    }
  },
}
</script>

<style lang="scss" scoped>
$main-width: 100%;

.member-container {
  background: #fafafa;
  padding-top: 60px;
  min-width: 1200px;

  .content {
    transform: translateY(-55px);
  }

  .main {
    width: $main-width;
  }

  .tab-wrap {
    background: #FFFFFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);

    .container_media {
      display: flex;
      align-items: center;
      height: 50px;
    }

    .tab {
      margin-right: 48px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .el-image {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }

    .active {
      font-weight: 600;
      color: #E52A0D;
      position: relative;

      &::before {
        content: '';
        width: 100%;
        height: 3px;
        background: #E52A0D;
        border-radius: 1px;
        position: absolute;
        bottom: -17px;
      }
    }
  }
}
</style>