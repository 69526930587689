<template>
  <div class="process-introduction">
    <div class="banner-wrap">
      <div class="process-wrap container_media">
        <div class="flex-rowShow">
          <div v-for="(introduction, index) in list" :key="index + 'list'" class="process-introduction-item">
            <div class="process-introduction-item-img">
              <img :src="introduction.icon" alt="" />
            </div>
            <div class="process-introduction-item-title">
              {{ introduction.title }}
            </div>
            <div class="process-introduction-item-desc" v-html="introduction.desc" />
          </div>
        </div>
        <div v-for="(introduction, index) in list1" :key="index + 'list1'" class="process-introduction-item">
          <div class="process-introduction-item-img">
            <img :src="introduction.icon" alt="" />
          </div>
          <div class="process-introduction-item-title">
            {{ introduction.title }}
          </div>
          <div class="process-introduction-item-desc" v-html="introduction.desc" />
        </div>
        <div class="flex-rowShow">
          <div v-for="(introduction, index) in list2" :key="index + 'list2'" class="process-introduction-item">
            <div class="process-introduction-item-img">
              <img :src="introduction.icon" alt="" />
            </div>
            <div class="process-introduction-item-title">
              {{ introduction.title }}
            </div>
            <div class="process-introduction-item-desc" v-html="introduction.desc" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcessIntroduction',
  data() {
    return {
      list: [{
        title: '01、 购买会员',
        desc: '购买您适用的会员套餐',
        icon: require('@/assets/image/member/process-01.png')
      },
      {
        title: '02、 下载协议',
        desc: '&nbsp;&nbsp;个人中心 -【我的会员】-【签署协议】<br>下载《订阅服务协议》',
        icon: require('@/assets/image/member/process-02.png')
      }],
      list1: [{
        title: '03、 下载素材',
        desc: '',
        icon: require('@/assets/image/member/process-03.png')
      }],
      list2: [{
        title: '04、 激活授权',
        desc: '个人中心 -【我的授权】<br>【激活授权书】',
        icon: require('@/assets/image/member/process-04.png')
      },
      {
        title: '05、 完成发布',
        desc: '进行作品制作<br>完成作品投放',
        icon: require('@/assets/image/member/process-05.png')
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.process-introduction {
  .banner-wrap {
    @include size(100%, 240px);
    background: transparent url('~@/assets/image/member/top_bg.jpg') no-repeat 50% / cover;
    padding: 30px 0;
    box-sizing: border-box;

    .process-wrap {
      @include size(100%, 100%);
      background-image: url('~@/assets/image/member/bj.png');
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      // padding: 30px 72px 28px 61px;
      padding: 30px 0px 28px 0px;

      .flex-rowShow {
        display: flex;
        justify-content: space-between;
        width: calc(75% / 2);

        .process-introduction-item {
          width: 100%
        }
      }

      .process-introduction-item-img img {
        @include size(40px, 40px);
        margin: 0 auto;
        display: block;
      }

      .process-introduction-item-title {
        font-size: 16px;
        font-family: AlibabaPuHuiTiB;
        color: rgba(255, 255, 255, 0.85);
        line-height: 22px;
        margin: 16px 0 8px;
        text-align: center;
        font-weight: 700;
      }

      .process-introduction-item-desc {
        font-size: 12px;
        font-family: AlibabaPuHuiTiR;
        color: rgba(255, 255, 255, 0.6);
        line-height: 18px;
        text-align: center;
        white-space: nowrap;
      }
    }
  }
}
</style>
