<template>
  <div class="jurisdiction-wrap">
    <div class="head-wrap">
      <img
        class="user-avatar"
        src="https://qkodo.playlistmusic.com.cn/default%2Fdefault-avatr-2022061011.png"
      />
      <p class="name">{{ userInfo.name }}</p>
      <!-- <span class="member_type">
        当前会员：企业年会员
        <span class="upgrade">升级会员</span>
      </span> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['userInfo'])
  }
}
</script>

<style lang="scss" scoped>
.jurisdiction-wrap {
  background: #fafafa;
  .head-wrap {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    padding-bottom: 24px;
    transform: translateY(-55px);
    .user-avatar {
      @include size(120px, 120px);
    }
    .name {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      margin: 16px 0 6px;
    }
    .member_type {
      font-weight: 400;
      color: rgba(51, 51, 51, 0.48);
      line-height: 20px;
      position: relative;
      .upgrade {
        @include size(68px, 24px);
        font-size: 12px;
        font-weight: 400;
        color: #e02020;
        background: rgba(229, 42, 13, 0.1);
        border-radius: 4px;
        border: 1px solid #e52a0d;
        line-height: 24px;
        position: absolute;
        right: -88px;
        top: -2px;
        cursor: pointer;
      }
    }
  }
}
</style>